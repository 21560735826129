<template>
  <video
    id="umanize-video"
    class="video azuremediaplayer amp-big-play-centered amp-default-skin"
    :autoplay="autoplay"
    controls
    poster="poster.jpg"
    data-setup='{"logo": { "enabled": false }, "techOrder": ["azureHtml5JS", "flashSS", "html5FairPlayHLS","silverlightSS", "html5"], "nativeControlsForTouch": false}'
  >
    <source :src="videoUrl" type="application/vnd.ms-sstr+xml" />
    <p class="amp-no-js">
      To view this video please enable JavaScript, and consider upgrading to a web browser that
      supports HTML5 video
    </p>
  </video>
</template>

<script>
export default {
  name: 'UmanizeVideo',
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    onVideoEnd: {
      type: Function,
    },
  },
  data: () => ({
    player: null,
  }),
  methods: {
    onPlay() {
      this.$emit('play');
    },
    onPause() {
      this.$emit('stop');
    },
    onEnded() {
      this.$emit('end');
    },
    onError(error) {
      console.log(error);
    },
  },
  mounted() {
    this.player = window.amp('umanize-video');

    this.player.addEventListener(window.amp.eventName.play, this.onPlay);
    this.player.addEventListener(window.amp.eventName.pause, this.onPause);
    this.player.addEventListener(window.amp.eventName.ended, this.onEnded);
    this.player.addEventListener(window.amp.eventName.error, this.onError);

    this.player.play();
  },
  beforeDestroy() {
    this.player.dispose();
  },
};
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
  height: 100%;
}
</style>
