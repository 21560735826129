<template>
  <iframe v-if="user && zoomMeetingId && zoomMeetingPassword" id="zoom" :src="src"></iframe>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'Zoom',
  props: ['user', 'zoomMeetingId', 'zoomMeetingPassword'],
  computed: {
    src() {
      const url = '/zoom.html';
      const params = `?zoomMeetingId=${this.zoomMeetingId}&zoomMeetingPwd=${this.zoomMeetingPassword}&username=${this.user.firstName}
      ${this.user.lastName}&email=${this.user.email}&lang=${i18n.locale}`;

      return `${url}${params}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

#zoom {
  width: 100vw;
  height: calc(100vw * (9 / 16));
  position: relative;
  border: none;
}

@include breakpoint(medium) {
  #zoom {
    width: calc(100vw / 1.6);
    height: calc((100vw / 1.6) * (9 / 16) + 52px);
    position: relative;
  }
}
</style>
