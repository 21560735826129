<template>
  <div>
    <umanize-video
      v-if="isUmanizeVideoType"
      :video-url="video.vimeoUrl"
      @play="play"
      @stop="stop"
    />

    <vimeo-video
      v-if="isVimeoVideoType"
      :videoUrl="video.vimeoUrl"
      :disableSound="disableSound"
      @play="play"
      @stop="stop"
    />

    <zoom
      v-if="isZoomMeeting && user"
      :user="user"
      :zoomMeetingId="video.zoomMeetingId"
      :zoomMeetingPassword="video.zoomMeetingPassword"
    />

    <div v-if="isZoomInfo" class="zoom-info">
      <h3>{{ $t('video-switcher.meeting.title') }}</h3>
      <p>
        <b>{{ $t('video-switcher.meeting.id') }}</b>
        {{ video.zoomMeetingId }}
      </p>
      <p>
        <b>{{ $t('video-switcher.meeting.pwd') }}</b>
        {{ video.zoomMeetingPassword }}
      </p>
    </div>
  </div>
</template>

<script>
import VimeoVideo from '@/components/video/Video.vue';
import { VideoType } from '@/models/video-type/video-type.model';
import UmanizeVideo from '@/components/umanize-video/UmanizeVideo.vue';
import Zoom from '../zoom/Zoom.vue';

export default {
  name: 'VideoSwitcher',
  description: 'Shows either the vimeo video (first) or the zoom meeting (second)',
  components: {
    UmanizeVideo,
    VimeoVideo,
    Zoom,
  },
  props: {
    user: {
      type: Object,
      required: false,
    },
    video: {
      type: Object,
      props: {
        videoType: {
          type: String,
          required: true,
        },
        vimeoUrl: {
          type: String,
          required: false,
        },
        videoUrl: {
          type: String,
          required: false,
        },
        zoomMeetingId: {
          type: String,
          required: false,
        },
        zoomMeetingPassword: {
          type: String,
          required: false,
        },
      },
    },
    disableSound: {
      type: Boolean,
      required: false,
      default: false,
    },
    showZoomInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isVimeoVideoType() {
      return this.video.videoType === VideoType.VIMEO;
    },
    isZoomVideoType() {
      return this.video.videoType === VideoType.ZOOM;
    },
    isUmanizeVideoType() {
      return this.video.videoType === VideoType.UMANIZE;
    },
    isZoomMeeting() {
      return this.isZoomVideoType && !this.showZoomInfo;
    },
    isZoomInfo() {
      return this.isZoomVideoType && this.showZoomInfo;
    },
  },
  methods: {
    play() {
      this.$emit('play');
    },
    stop() {
      this.$emit('stop');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';

.zoom-info {
  background-color: var(--v-darkerGray-base);
  height: 100%;
  color: white;
  padding: 2rem;

  h3 {
    font-size: $xx-large-font-size;
    text-align: center;
  }

  p {
    padding-left: 2rem;
    margin-top: 2rem;
  }
}
</style>
